/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { matchPath } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';

import { classNames } from '@modules/@nxweb/core/cjs/strings';

import { useAuth } from '@nxweb/auth';
import { Power } from '@nxweb/icons/feather';

import { Avatar } from '@components/avatar';
import { IconBell, IconChevronDown } from '@components/icon';
import { USER_DROPDOWN_ITEMS } from '@config/navbar';
import { RouteConfig } from '@config/routes';

const UserDropdown = ({ fixedColor = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const auth = useAuth();
  const loggedIn = auth.data?.loggedIn;
  const user = auth.data?.user;

  const [colorChange, setColorChange] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 1) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };

  useEffect(() => {
    const match = RouteConfig.filter(
      (route) => route?.meta?.navColor && matchPath(route.path, currentPath)
    );

    if (match.length > 0) {
      setColorChange(true);
      window.removeEventListener('scroll', changeNavbarColor);
    } else {
      window.removeEventListener('scroll', changeNavbarColor);
      window.addEventListener('scroll', changeNavbarColor);
    }

    return () => {
      window.removeEventListener('scroll', changeNavbarColor);
    };
  }, []);

  const textColor = {
    'text-netral-9': fixedColor || colorChange,
    'text-white': !fixedColor && !colorChange
  };

  return (
    <>
      {loggedIn
        ? <div>
          <Dropdown as="li" className="dropdown-user nav-item w-100 navbar-dropdown-override">
            <Dropdown.Toggle as="a" className="nav-link dropdown-user-link d-flex">
              <div
                className={classNames('me-2', textColor)}
              >
                <IconBell />
              </div>
              {user?.picture
                ? <Avatar className="me-1" img={user?.picture} imgHeight="40" imgWidth="40" />
                : <Avatar className="me-1" color="light-secondary" content={user?.name || 'Guest User'} initials={true} />}
              <div className="user-nav d-sm-flex d-none align-self-center">
                <span
                  className={classNames('text-btn-1 fw-bold me-2', textColor)}
                >
                  {user?.name || 'Guest User'}
                </span>
                {/* <span className="user-status">{user?.email}</span> */}
                <IconChevronDown className="align-self-center" fill="#646464" size={16} />
              </div>

            </Dropdown.Toggle>
            {loggedIn
              ? <Dropdown.Menu align="end">
                {USER_DROPDOWN_ITEMS.map((o) => {
                  return (
                    <Dropdown.Item className="d-flex align-items-center" disabled={o.disabled} href={o.to} key={o.title}>
                      {o.icon}
                      <span className="align-middle">{o.title}</span>
                    </Dropdown.Item>
                  );
                })}
                <Dropdown.Item className="d-flex align-items-center text-danger" onClick={() => auth.logout()}>
                  <Power size={14} />
                  <span>Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
              : null}
          </Dropdown>
        </div>
        :
        // null
        <div>
          <Button className="me-1" size="sm" variant="primary" onClick={() => navigate('/daftar')}>Sign Up</Button>
          <Button className="btn-outline-primary" size="sm" onClick={() => auth.loginWithRedirect()}>Login</Button>
        </div>
      }
    </>

  );
};

UserDropdown.displayName = 'UserDropdown';
export { UserDropdown };
