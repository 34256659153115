import { lazy } from 'react';

import type { RouteType } from '@nxweb/core/router';

export const OthersRoutes: RouteType[] = [
  {
    component: lazy(() => import('@views/others/others.js').then((m) => ({ default: m.Others }))),
    // layout: 'blank',
    path: '/teknologi',
    title: 'Teknologi',
    meta: { navColor: true }
  },
  // Footer
  {
    component: lazy(() => import('@views/others/faq.js').then((m) => ({ default: m.FAQPage }))),
    path: '/faq',
    title: 'Frequently Asked Questions',
    meta: { navColor: true }
    // layout: 'blank'
  },
  {
    component: lazy(() => import('@views/others/produk.js').then((m) => ({ default: m.TimDanProduk }))),
    path: '/produk',
    title: 'Produk',
    meta: { navColor: true }
    // layout: 'blank'
  },
  {
    component: lazy(() => import('@views/others/produk.js').then((m) => ({ default: m.TimDanProduk }))),
    path: '/tim-manajemen',
    title: 'Tim Manajemen',
    meta: { navColor: true }
    // layout: 'blank'
  },
  {
    component: lazy(() => import('@views/others/others.js').then((m) => ({ default: m.Others }))),
    path: '/tentang-kami',
    title: 'Tentang Kami',
    meta: { navColor: true }
    // layout: 'blank'
  },
  {
    component: lazy(() => import('@views/others/karir.js').then((m) => ({ default: m.Karir }))),
    path: '/karir',
    title: 'Karir',
    meta: { navColor: true }
    // layout: 'blank'
  }
];
