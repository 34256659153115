export interface PassengerDataModel {
  [key: string]: unknown
  birthDate: string
  confirmRequested?: boolean
  expireDate: string
  firstName: string
  issueDate: string
  lastName: string
  middleName: string
  nationality: string
  number?: string
  passengerId?: string
  passportIssuingCity: string
  passportNumber: string
  title: string
}

export interface Passengers {
  birthDate: string
  expireDate: string
  firstName: string
  issueDate: string
  lastName: string
  middleName: string
  nationality: string
  passengerId?: string
  passportIssuingCity: string
  passportNumber: string
  title: string
}

export interface RequestChangeNamePayloadDataModel {
  number: string
  passengers: Passengers[]
}

export enum PassengerActionType {
  GetPassengerTable = '⌘➝App➝Daftar-Passenger➝GetPassengerTable',
  GetPassengerDetail = '⌘➝App➝Daftar-Passenger➝GetPassengerDetail',
  GetExcelList = '⌘➝App➝Passenger➝GetDataExcel',
  GetPassengerConfirmationList = '⌘➝App➝Passenger➝GetPassengerConfirmationList'
}

export interface PassengerModel {
  excel: Passengers[]
  main: {
    data: PassengerDataModel[]
    total: number
  }
  passengerConfirmationList: PassengerDataModel[]
  passengerDetail: PassengerDataModel
}

export type PassengerAction = {
  data: {
    data: PassengerDataModel[]
    total: number
  }
  type: PassengerActionType.GetPassengerTable
} | {
  data: PassengerDataModel
  type: PassengerActionType.GetPassengerDetail
} | {
  data: Passengers[]
  type: PassengerActionType.GetExcelList
};
