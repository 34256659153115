import { FooterComp } from './footer-comp';

/*
 * Interface FooterProps {
 *   setMenuVisibility?: (visible: boolean) => void
 * }
 */

/*
 * const Footer: FC<FooterProps> = ({
 *   setMenuVisibility
 * }) => {
 *   return (
 *     <FooterComp setMenuVisibility={setMenuVisibility} />
 *   );
 * };
 */

const Footer = () => {
  return (
        <FooterComp />
  );
};

Footer.displayName = 'Footer';

export { Footer };
