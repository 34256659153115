import { Home } from '@nxweb/icons/feather';

import { Avatar } from '@components/avatar';
import type { NavigationConfig as NavigationConfigType } from '@layouts/types';

const NavigationConfig: NavigationConfigType = [
  {
    id: 'home',
    text: 'Home',
    icon: <Avatar className="me-1" icon={<Home />} />,
    navLink: '/'
  }
  ,
  {
    id: 'components',
    text: 'Componetns',
    icon: <Avatar className="me-1" icon={<Home />} />,
    navLink: '/components-preview'
  }
  // ,
  // {
  //   id: 'informasi-pemesanan',
  //   text: 'informasi-pemesanan',
  //   icon: <Avatar className="me-1" icon={<Home />} />,
  //   navLink: '/informasi-pemesanan'
  // }
  // ,
  // {
  //   id: 'blog',
  //   text: 'blog',
  //   icon: <Avatar className="me-1" icon={<Home />} />,
  //   navLink: '/blog'
  // }
];

export { NavigationConfig };
