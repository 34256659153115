export interface KhazanahBlogDataModel {
  _id: string
  _key: string
  _rev: string
  article: string
  createdOn: string
  img: string
  published: boolean
  thumbnail: string
  title: string
}

export interface BlogModel {
  khazanahBlog: KhazanahBlogDataModel
}

export enum BlogActionType {
  GetKhazanahBlogList = '⌘➝App➝Blog➝GetKhazanahBlogList'
}

export interface BlogAction {
  data: KhazanahBlogDataModel
  type: BlogActionType.GetKhazanahBlogList
}
