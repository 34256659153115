import basex from 'base-x';

import { NavigationConfig } from '@config/navigation';
import type { NavigationLink } from '@layouts/types';

function joinUniqueArray<T>(arr1: T[], arr2: T[]): T[] {
  const res = arr1.concat(arr2.filter((item) => arr1.indexOf(item) < 0));

  return res;
}

function deduplicateArray<T>(arr1: T[], arr2: T[]): T[] {
  const res = arr1.filter((o) => !arr2.includes(o));

  return res;
}

function flattenNav() {
  const result = [];
  const arr: NavigationLink[] = JSON.parse(JSON.stringify(NavigationConfig));

  for (const obj of arr) {
    if (obj.children) {
      result.push(...obj.children);
      delete obj.children;
    } else {
      result.push(obj);
    }
  }

  return result;
}

function stringToArray(str: string) {
  const res = str.replaceAll(/\s/gu, '');

  if (res.length) {
    return res.split(',');
  }

  return null;
}

function separateNumber(num: number) {
  return new Intl.NumberFormat('id-ID').format(num);
}

function paginateArray<T>(array: T[], perPage: number, page: number): T[] {
  return array.slice((page - 1) * perPage, page * perPage);
}

function compare2Object(obj1: Record<string, unknown>, obj2: Record<string, unknown>) {
  let objEqual = false;
  const obj1Keys = Object.keys(obj1).sort();
  const obj2Keys = Object.keys(obj2).sort();
  if (obj1Keys.length === obj2Keys.length) {
    const areEqual = obj1Keys.every((key, index) => {
      const objValue1 = obj1[key];
      const objValue2 = obj2[obj2Keys[index]];

      return objValue1 === objValue2;
    });
    if (areEqual) {
      objEqual = true;
    }
  }

  return objEqual;
}

const BASE58 = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
const bs58 = basex(BASE58);
const decoder = new TextDecoder();

export { compare2Object, deduplicateArray, joinUniqueArray, flattenNav, stringToArray, separateNumber, paginateArray, bs58, decoder };
