import type { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { IconAlteira3 } from '@components/icon';

interface FooterCompProps {
  setMenuVisibility?: (visible: boolean) => void
}

const scrollToSolutions = () => {
  const footerElement = document.getElementById('section-solutions');

  footerElement?.scrollIntoView({ behavior: 'smooth' });
};

const scrollToProducts = () => {
  const footerElement = document.getElementById('section-products');

  footerElement?.scrollIntoView({ behavior: 'smooth' });
};

const SOLUTIONS = [
  {
    link: scrollToSolutions,
    name: 'B2B Commerce'
  },
  {
    link: scrollToSolutions,
    name: 'B2C Commerce'
  },
  {
    link: scrollToSolutions,
    name: 'Marketplace'
  }
];

const PRODUCTS = [
  {
    link: scrollToProducts,
    name: 'Airlines'
  },
  {
    link: scrollToProducts,
    name: 'Hotel'
  },
  {
    link: scrollToProducts,
    name: 'Train'
  },
  {
    link: scrollToProducts,
    name: 'Bus'
  },
  {
    link: scrollToProducts,
    name: 'Visa'
  },
  {
    link: scrollToProducts,
    name: 'Meal'
  }
];

const COMPANY = [
  {
    link: '/tentang-kami',
    name: 'About ALTEIRA'
  },
  {
    link: '/#location',
    name: 'Location'
  },
  {
    link: '/#patners',
    name: 'Patner\'s'
  },
  {
    link: '/#contact',
    name: 'Contact Us'
  }
];

const FooterComp: FC<FooterCompProps> = () => {
  return (
    <div id="footer">
      <div className="footer-control">
        <Row>
          <Col md={6}>
            <div className="footer-logo text-center mx-auto mx-md-0">
              <Link to="/">
                <IconAlteira3 height={150} width={150} />
              </Link>
              <div className="text-body-1 fw-medium text-netral-9 mt-1 mb-3 mb-md-0">
                <div>Jalan Hegarmanah No. 28</div>
                <div>Bandung 40141</div>
              </div>
            </div>
          </Col>
          <Col className="mb-2" md={2}>
            <div className="d-flex flex-column gap-25">
              <h5 className="fw-bold text-netral-10">Solutions</h5>
              {SOLUTIONS.map((x) => {
                return <Link className="text-body-1 fw-medium text-netral-9" key={x.name} to={typeof x.link === 'string' ? x.link : '#'} onClick={() => (typeof x.link !== 'string' ? x.link() : {})}>{x.name}</Link>;
              })}
            </div>
          </Col>
          <Col className="mb-2" md={2}>
            <div className="d-flex flex-column gap-25">
            <h5 className="fw-bold text-netral-10">Products</h5>
              {PRODUCTS.map((x) => {
                return <Link className="text-body-1 fw-medium text-netral-9" key={x.name} to={typeof x.link === 'string' ? x.link : '#'} onClick={() => (typeof x.link !== 'string' ? x.link() : {})}>{x.name}</Link>;
              })}
            </div>
          </Col>
          <Col md={2}>
            <div className="d-flex flex-column gap-25">
            <h5 className="fw-bold text-netral-10">Company</h5>
              {COMPANY.map((x) => {
                return <Link className="text-body-1 fw-medium text-netral-9" key={x.name} to={x.link}>{x.name}</Link>;
              })}
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="d-flex justify-content-center text-primary fw-medium">
          Copyright ©{new Date().getFullYear()} All rights reserved | ALTEIRA
        </Row>

      </div>
    </div>

  );
};

FooterComp.displayName = 'FooterComp';

export { FooterComp };
export type { FooterCompProps };
