import type { FC, PropsWithChildren, ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Button, Navbar } from 'react-bootstrap';
import { matchPath, useLocation } from 'react-router-dom';

import { classNames } from '@nxweb/core/strings';
import { ArrowUP } from '@nxweb/icons/bootstrap';

import { Footer as FooterComp } from '@components/footer';
import { Navbar as NavbarComponent } from '@components/navbar';
import { ScrollTop } from '@components/scroll-top';
import { Sidebar as SidebarComponent } from '@components/sidebar';
import { RouteConfig } from '@config/routes';

import { useLayout } from './provider';

type DefaultLayoutProps = PropsWithChildren<{
  className?: string
  currentActiveItem: unknown

  footer?: ReactNode
  menu?: ReactNode
  navbar?: ReactNode
  banner?: ReactNode

  routerProps: unknown
}>;

const DEFAULT_WIDTH = 1200;

const DefaultLayout: FC<DefaultLayoutProps> = ({
  className,
  footer,
  navbar,
  menu,
  routerProps,
  currentActiveItem,
  children
}) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const layout = useLayout();
  const { windowWidth, menuCollapsed, menuHidden, transition } = layout;
  const [menuVisibility, setMenuVisibility] = useState(false);

  const [colorChange, setColorChange] = useState(false);
  const [openNavbar, setOpenNavbar] = useState(false);

  const noFooter = useMemo(() => {
    const match = RouteConfig.filter(
      (route) => route?.meta?.noFooter && matchPath(route.path, currentPath)
    );

    return match.length > 0;
  }, [currentPath]);

  const toggleOpenNavbar = () => setOpenNavbar(!openNavbar);

  const changeNavbarColor = () => {
    if (openNavbar) return;

    if (window.scrollY >= 1) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };

  useEffect(() => {
    const match = RouteConfig.filter(
      (route) => route?.meta?.navColor && matchPath(route.path, currentPath)
    );
    const resizeWindow = () => layout?.updateWindowWidth();

    if (typeof window === 'undefined') return;

    window.addEventListener('resize', resizeWindow);

    if (match.length > 0) {
      setColorChange(true);
      window.removeEventListener('scroll', changeNavbarColor);
    } else {
      window.removeEventListener('scroll', changeNavbarColor);
      window.addEventListener('scroll', changeNavbarColor);
    }

    return () => {
      window.removeEventListener('scroll', changeNavbarColor);
      window.removeEventListener('resize', resizeWindow);
    };
  }, []);

  useEffect(() => {
    if (menuVisibility && windowWidth < DEFAULT_WIDTH) {
      setMenuVisibility(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div
      className={classNames(
        className,
        `wrapper vertical-layout navbar-floating footer-hidden`,
        {
          // Modern Menu
          'vertical-menu-modern': windowWidth >= DEFAULT_WIDTH,
          'menu-collapsed': menuCollapsed && windowWidth >= DEFAULT_WIDTH,
          'menu-expanded': !menuCollapsed && windowWidth > DEFAULT_WIDTH,

          // Overlay Menu
          'vertical-overlay-menu': windowWidth < DEFAULT_WIDTH,
          'menu-hidden': !menuVisibility && windowWidth < DEFAULT_WIDTH,
          'menu-open': menuVisibility && windowWidth < DEFAULT_WIDTH
        }
      )}
      {...(menuHidden ? { 'data-col': '1-column' } : {})}
    >
      {!menuHidden
        ? (
          <SidebarComponent
            currentActiveItem={currentActiveItem}
            menu={menu}
            routerProps={routerProps} />
        )
        : null}

      <Navbar
        /*
         * ClassName={classNames(`header-navbar navbar align-items-center floating-nav navbar-shadow`)}     #yangasli
         * className={classNames(`header-navbar navbar align-items-center navbar-sticky`)}     #matikan untuk navbar transparan
         */
        className={classNames(`header-navbar navbar align-items-center navbar-sticky`, { 'color-change': openNavbar || colorChange })}
        expand="lg"
        sticky="top"
      >
        <div className="d-flex w-100 py-75">
          {navbar || <NavbarComponent colorChange={colorChange} openNavbar={openNavbar} toggleOpenNavbar={toggleOpenNavbar} />}
        </div>
      </Navbar>

      <div className={classNames('app-content content overflow-hidden')}>
        <div className="content-overlay" />
        <div className="header-navbar-shadow" />
        <div
          className={classNames('content-wrapper', {
            [`animate__animated animate__${transition}`]: transition !== 'none' && transition.length
          })}
        >
          {children}
        </div>
      </div>

      {/* Vertical Nav Menu Overlay */}
      <div
        className={classNames('sidenav-overlay', {
          show: menuVisibility
        })}
        onClick={() => setMenuVisibility(false)} />
      {/* Vertical Nav Menu Overlay */}

      {noFooter
        ? null
        : <footer className={classNames(`footer-light`)}>
          {footer || <FooterComp />}
        </footer>}

      {layout.scrollTop
        ? (
          <ScrollTop className="scroll-top d-block" showOffset={300}>
            <Button className="btn-icon" color="primary">
              <ArrowUP size={14} />
            </Button>
          </ScrollTop>
        )
        : null}
    </div>
  );
};

DefaultLayout.displayName = 'DefaultLayout';
export { DefaultLayout };
