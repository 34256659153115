/* eslint-disable sort-keys */
/* eslint-disable import/max-dependencies */
import { lazy } from 'react';

import type { RouteType } from '@nxweb/core/router';

import { OthersRoutes } from './others';

export const RouteConfig: RouteType[] = [
  // Base
  {
    component: lazy(() => import('@views/beranda/index.js').then((m) => ({ default: m.Beranda }))),
    path: '/',
    title: 'Home'
  },
  {
    component: lazy(() => import('@views/landing/index.js').then((m) => ({ default: m.Landing }))),
    path: '/booking',
    title: 'Booking'
  },
  {
    component: lazy(() => import('@views/components/index.js').then((m) => ({ default: m.ComponentPreview }))),
    path: '/components-preview',
    title: 'Components Preview'
  },
  // Credentials
  {
    component: lazy(() => import('@views/credentials/daftar.js').then((m) => ({ default: m.Daftar }))),
    path: '/daftar',
    layout: 'blank',
    title: 'Daftar'

  },
  {
    component: lazy(() => import('@views/credentials/aktivasi.js').then((m) => ({ default: m.Aktivasi }))),
    path: '/aktivasi',
    title: 'Aktivasi',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/credentials/login.js').then((m) => ({ default: m.Login }))),
    path: '/login',
    layout: 'blank',
    title: 'Login'
  },

  // Profile
  {
    component: lazy(() => import('@views/profile/form-profile.js').then((m) => ({ default: m.Profile }))),
    path: '/profile',
    title: 'Profile',
    layout: 'blank'
  },

  // Landing
  {
    component: lazy(() => import('@views/beranda/index.js').then((m) => ({ default: m.Beranda }))),
    path: '/beranda',
    title: 'Beranda'
  },
  {
    component: lazy(() => import('@views/blog/index.js').then((m) => ({ default: m.BlogPage }))),
    path: `/blog`,
    title: 'Blog',
    meta: { navColor: true, noFooter: true }
  },
  {
    component: lazy(() => import('@views/promo/index.js').then((m) => ({ default: m.Promo }))),
    path: '/promo',
    title: 'Promo',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/promo/promo-detail.js').then((m) => ({ default: m.PromoDetail }))),
    path: `/promo/:id`,
    title: 'Detail Promo',
    layout: 'blank'
  },
  // Pembayaran Booking Fee (Hasil Pencarian)
  {
    component: lazy(() => import('@views/search-result/index.js').then((m) => ({ default: m.SearchResult }))),
    path: '/hasil-pencarian',
    title: 'Hasil pencarian',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/informasi-pemesanan/index.js').then((m) => ({ default: m.InformasiPemesanan }))),
    path: '/informasi-pemesanan',
    title: 'Informasi Pemesanan',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/pembayaran/pembayaran.js').then((m) => ({ default: m.Pembayaran }))),
    path: '/pembayaran/:pax/:id',
    title: 'Pembayaran',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/konfirmasi-pembayaran/konfirmasi-pembayaran.js').then((m) => ({ default: m.KonfirmasiPembayaran }))),
    path: '/konfirmasi-pembayaran/:id/:pax',
    title: 'Kofirmasi Pembayaran',
    layout: 'blank'
  },
  // Pesanan
  {
    component: lazy(() => import('@views/pesanan/index.js').then((m) => ({ default: m.Pesanan }))),
    path: '/pesanan',
    title: 'Pesanan',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/pesanan/detail-pesanan/index.js').then((m) => ({ default: m.DetailPesanan }))),
    path: '/pesanan/:id/detail-pesanan',
    title: 'Detail Pesanan',
    layout: 'blank'
  },
  // Pesanan > Penumpang
  {
    component: lazy(() => import('@views/penumpang/index.js').then((m) => ({ default: m.Penumpang }))),
    path: '/pesanan/:orderId/daftar-penumpang',
    title: 'Daftar Penumpang',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/penumpang/detail-penumpang.js').then((m) => ({ default: m.DetailPenumpang }))),
    path: '/pesanan/:orderId/daftar-penumpang/detail-penumpang/:passengerId',
    title: 'Detail Penumpang',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/penumpang/rename-penumpang.js').then((m) => ({ default: m.RenamePenumpang }))),
    path: '/pesanan/:orderId/daftar-penumpang/ganti-nama/pilih',
    title: 'Ganti Nama Penumpang',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/penumpang/rename-penumpang.js').then((m) => ({ default: m.RenamePenumpang }))),
    path: '/pesanan/:orderId/daftar-penumpang/koreksi-nama/pilih',
    title: 'Ganti Nama Penumpang',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/penumpang/detail-rename-penumpang.js').then((m) => ({ default: m.DetailRenamePenumpang }))),
    path: '/pesanan/:orderId/daftar-penumpang/ganti-nama',
    title: 'Ganti Nama Penumpang',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/penumpang/edit-penumpang.js').then((m) => ({ default: m.EditPenumpang }))),
    path: '/pesanan/:orderId/daftar-penumpang/ganti-nama/edit-penumpang/:passengerId',
    title: 'Edit Penumpang',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/penumpang/edit-penumpang.js').then((m) => ({ default: m.EditPenumpang }))),
    path: '/pesanan/:orderId/daftar-penumpang/edit-penumpang/:passengerId',
    title: 'Edit Penumpang',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/penumpang/preview-change-name-penumpang.js').then((m) => ({ default: m.PreviewChangeNamePenumpang }))),
    path: '/pesanan/:orderId/daftar-penumpang/preview-ganti-nama-penumpang',
    title: 'Preview Ganti Nama Penumpang',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/penumpang/tambah-penumpang.js').then((m) => ({ default: m.TambahPenumpang }))),
    path: '/pesanan/:orderId/daftar-penumpang/tambah-penumpang',
    title: 'Tambah Penumpang',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/penumpang/daftarkan-penumpang.js').then((m) => ({ default: m.DaftarkanPenumpang }))),
    path: '/pesanan/:orderId/daftar-penumpang/tambah-penumpang/daftarkan-penumpang',
    title: 'Daftarkan Penumpang',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/penumpang/upload-excel.js').then((m) => ({ default: m.UploadExcel }))),
    path: '/pesanan/:orderId/daftar-penumpang/bulk',
    title: 'Upload Excel',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/pelunasan/page-data-penumpang.js').then((m) => ({ default: m.PelunasanPenumpangComp }))),
    path: '/pesanan/:id/pelunasan/pembayaran/pengurangan-penumpang',
    title: 'data penumpang',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/pelunasan/penumpang-dibatalkan.js').then((m) => ({ default: m.PenumpangDibatalkanComp }))),
    path: '/pesanan/:id/pelunasan/pembayaran/pengurangan-penumpang/penumpang-dibatalkan',
    title: 'Pengurangan Penumpang',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/pelunasan/pembayaran-booking.js').then((m) => ({ default: m.PembayaranBookingFeeDanUangMuka }))),
    path: '/pesanan/:id/pelunasan/pembayaran',
    title: 'Pembayaran Booking Fee dan Uang Muka',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/pelunasan/perhitungan-pelunasan.js').then((m) => ({ default: m.PerhitunganPelunasan }))),
    path: '/pesanan/:id/pelunasan',
    title: 'Perhitungan Pelunasan',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/pesanan/letter-of-guarantee.js').then((m) => ({ default: m.LetterOfGuarantee }))),
    path: '/pesanan/:id/syarat-dan-ketentuan',
    title: 'Syarat dan Ketentuan',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/pesanan/ubah-kuantitas-pesanan/kuantitas-pesanan.js').then((m) => ({ default: m.KuantitasPesanan }))),
    path: '/pesanan/ubah-kuantitas-pesanan/kuantitas-pesanan',
    title: 'Kuantitas Pesanan',
    layout: 'blank'
  },
  {
    component: lazy(() => import('@views/pesanan/ubah-kuantitas-pesanan/perhitungan-pelunasan/perhitungan-pelunasan.js').then((m) => ({ default: m.PerhitunganPelunasan }))),
    path: '/pesanan/ubah-kuantitas-pesanan/perhitungan-pelunasan',
    title: 'Perhitungan Pelunasan',
    layout: 'blank'
  },
  ...OthersRoutes
  /*
   * {
   *   component: lazy(() => import('@views/pesanan/detail-pesanan/petunjuk-pembayaran.js').then((m) => ({ default: m.PetunjukPembayaran }))),
   *   path: '/pesanan/:id/petunjuk-pembayaran',
   *   title: 'Petunjuk Pembayaran',
   *   layout: 'blank'
   * },
   */
];
