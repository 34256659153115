/* eslint-disable import/exports-last */
import { apiFetch } from '@api/base';
import { notify } from '@lib/notify';
import type { TAction, TDispatch } from '@models/types';

import { PassengerActionType } from './types';

import type { PassengerAction, PassengerDataModel, PassengerModel, RequestChangeNamePayloadDataModel } from './types';

const PassengerDefault: PassengerModel = {
  excel: [],
  main: {
    data: [],
    total: 0
  },
  passengerConfirmationList: [],
  passengerDetail: {
    birthDate: '',
    expireDate: '',
    firstName: '',
    issueDate: '',
    lastName: '',
    middleName: '',
    nationality: '',
    number: '',
    passengerId: '',
    passportIssuingCity: '',
    passportNumber: '',
    title: ''
  }
};

export const defaultPassenger: PassengerDataModel = {
  birthDate: '',
  expireDate: '',
  firstName: '',
  issueDate: '',
  lastName: '',
  middleName: '',
  nationality: '',
  number: '',
  passengerId: '',
  passportIssuingCity: '',
  passportNumber: '',
  title: ''
};

const PassengerReducer = (state: PassengerModel = PassengerDefault, action: PassengerAction): PassengerModel => {
  switch (action.type) {
    case PassengerActionType.GetPassengerTable:
      return { ...state, main: action.data };
    case PassengerActionType.GetPassengerDetail:
      return { ...state, passengerDetail: action.data };

    default:
      return { ...state };
  }
};

const PassengerCommand = {
  addPenumpang: (params: unknown, token: string): Promise<boolean> => {
    return apiFetch(token).post(`/order/1.0/agent/add-passenger`, params).then((res) => {
      if (res.data.error === true) {
        notify.info('berhasil tambah data');

        return false;
      }

      return false;
    }).catch((err) => {
      console.error('fetch error', err);

      return false;
    });
  },
  ConfirmTableDaftarPenumpang: ({ number, passengerId }: { number?: string, passengerId?: string[] }, token: string): Promise<boolean> => {
    return apiFetch(token).post(`/order/1.0/agent/request-confirm-passengers`, { number, passengerId }).then((res) => {
      if (res.status === 200) {
        notify.success('Berhasil mengirim data');

        return true;
      }

      notify.error('empty data/ confirm status true');

      return false;
    }).catch((err) => {
      console.error('fetch error', err);

      return false;
    });
  },
  deleteTableDaftarPenumpang: ({ number, passengerId }: { number?: string, passengerId?: string }, token: string): Promise<boolean> => {
    return apiFetch(token).delete(`/order/1.0/agent/delete-passenger`, { params: { number, passengerId } }).then((res) => {
      if (res.data.message === 'success') {
        notify.success('Berhasil hapus data');

        return true;
      }

      if (res.data.error === true) {
        notify.info('Gagal hapus data');

        return false;
      }

      return false;
    }).catch((err) => {
      console.error('fetch error', err);

      return false;
    });
  },
  getDetailPenumpang: ({ number, passengerId }: { number?: string, passengerId?: string }, token: string): TAction<PassengerAction, void> => {
    return (dispatch: TDispatch<PassengerAction>) => {
      return apiFetch(token).post(`/order/1.0/agent/get-passenger-detail`, { number, passengerId })
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: PassengerActionType.GetPassengerDetail
          });
        });
    };
  },
  PostChangerPassenger: (payload: RequestChangeNamePayloadDataModel, token: string): Promise<string> => {
    return apiFetch(token).post('/order/1.0/agent/request-change-name', payload)
      .then((response) => {
        if (response.status === 200) {
          notify.success('Success');

          return 'ok';
        }

        return 'err';
      }).catch(() => {
        notify.error('Error');

        return 'err';
      });
  },
  PostPassengerTable: (params: unknown, token: string): TAction<PassengerAction, void> => {
    return (dispatch: TDispatch<PassengerAction>) => {
      return apiFetch(token).post('/order/1.0/agent/passengers-grid', params)
        .then((response) => {
          dispatch({
            data: response.data,
            type: PassengerActionType.GetPassengerTable
          });
        });
    };
  },
  PutPassenger: (payload: PassengerDataModel, token: string): Promise<string> => {
    return apiFetch(token).put('/order/1.0/agent/edit-passenger', payload)
      .then((response) => {
        if (response.status === 200) {
          notify.success('Success');

          return 'ok';
        }

        return 'err';
      }).catch(() => {
        notify.error('Error');

        return 'err';
      });
  }
};

export { PassengerCommand, PassengerReducer, PassengerDefault };
