import { Cart, Person, Wallet } from '@nxweb/icons/bootstrap';

const scrollToFooter = () => {
  const footerElement = document.getElementById('footer');

  footerElement?.scrollIntoView({ behavior: 'smooth' });
};

const scrollToSolutions = () => {
  const footerElement = document.getElementById('section-solutions');

  footerElement?.scrollIntoView({ behavior: 'smooth' });
};

const scrollToProducts = () => {
  const footerElement = document.getElementById('section-products');

  footerElement?.scrollIntoView({ behavior: 'smooth' });
};

const scrollToInsight = () => {
  const footerElement = document.getElementById('section-insight');

  footerElement?.scrollIntoView({ behavior: 'smooth' });
};

const NAVBAR_ITEMS = [
  {
    title: 'Home',
    to: '/'
  },
  {
    title: 'Booking',
    to: '/booking'
  },
  {
    title: 'Solutions',
    to: scrollToSolutions
  },
  {
    title: 'Products',
    to: scrollToProducts
  },
  {
    title: 'Insight',
    to: scrollToInsight
  },
  {
    title: 'Career',
    to: '/karir'
  }
  /*
   * {
   *   icon: <IconKetentuan />,
   *   title: 'Ketentuan',
   *   to: '/#ketentuan'
   * },
   */
  /*
   * {
   *   title: 'Tentang Kami',
   *   to: '/tentang-kami'
   * },
   */
  /*
   * {
   *   title: 'Contact Us',
   *   to: scrollToFooter
   * }
   */
];

const USER_DROPDOWN_ITEMS = [
  {
    icon: <Cart size={14} />,
    title: 'Pesanan',
    to: '/pesanan'
  },
  {
    disabled: true,
    icon: <Wallet size={14} />,
    title: 'Kantong Pengembalian',
    to: '/#'
  },
  {
    icon: <Person size={14} />,
    title: 'Profile',
    to: '/profile'
  }
];

export { NAVBAR_ITEMS, USER_DROPDOWN_ITEMS };
