import { APIDCN } from '@api/base';
import type { TAction, TDispatch } from '@models/types';

import { BlogActionType } from './types';

import type { BlogAction, BlogModel } from './types';

const BlogDefault: BlogModel = {
  khazanahBlog:
  {
    _id: '',
    _key: '',
    _rev: '',
    article: '',
    createdOn: '',
    img: '',
    published: false,
    thumbnail: '',
    title: ''
  }
};

const BlogReducer = (state: BlogModel = BlogDefault, action: BlogAction): BlogModel => {
  switch (action.type) {
    case BlogActionType.GetKhazanahBlogList:
      return { ...state, khazanahBlog: action.data };

    default:
      return { ...state };
  }
};

const BlogCommand = {
  getKhazanahBlogList: (params: string): TAction<BlogAction, void> => {
    return (dispatch: TDispatch<BlogAction>) => {
      return APIDCN.get(`/khazanah/1.0/agent/article?key=${params}`)
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: BlogActionType.GetKhazanahBlogList
          });
        });
    };
  }

};

export { BlogCommand, BlogReducer, BlogDefault };
