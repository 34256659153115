/* eslint-disable @typescript-eslint/no-explicit-any */
import { createFetch } from '@nxweb/core/fetch';

const API = createFetch();
const mock = API.mock({ delayResponse: 1000 });

const DATABASE_PESANAN = [
  {
    id: 1,
    nama: 'Andro Latukonsina'
  },
  {
    id: 2,
    nama: 'B'
  }
];

mock.onPost('/api/pesanan').reply((config: any) => {
  const { data } = config;

  const { id } = JSON.parse(data);

  let list = DATABASE_PESANAN;

  if (id) {
    list = list.filter((o) => o.id === id);
  }

  return [200, { data: list, total: DATABASE_PESANAN.length }];
});

const DATABASE_MASKAPAI = [
  {
    src: 'https://nextmail-api.dcnlink.com/media/qatar_airways_logo.svg',
    description: 'Etihad Airways 1 adalah maskapai penerbangan nasional dari Uni Emirat Arab yang didirikan pada tahun 2003. Maskapai ini melayani lebih dari 120 destinasi di seluruh dunia, termasuk kota-kota besar di Timur Tengah, Eropa, Amerika Serikat, dan Asia.Etihad Airways adalah maskapai penerbangan nasional dari Uni Emirat Arab yang didirikan pada tahun 2003. Maskapai ini melayani lebih dari 120 destinasi di seluruh dunia, termasuk kota-kota besar di Timur Tengah, Eropa, Amerika Serikat, dan Asia.Etihad Airways adalah maskapai penerbangan nasional dari Uni Emirat Arab yang didirikan pada tahun 2003. Maskapai ini melayani lebih dari 120 destinasi di seluruh dunia, termasuk kota-kota besar di Timur Tengah, Eropa, Amerika Serikat, dan Asia.'
  },
  {
    src: 'https://nextmail-api.dcnlink.com/media/ethiopian_airlines_logo.svg',
    description: 'Etihad Airways 2 adalah maskapai penerbangan nasional dari Uni Emirat Arab yang didirikan pada tahun 2003. Maskapai ini melayani lebih dari 120 destinasi di seluruh dunia, termasuk kota-kota besar di Timur Tengah, Eropa, Amerika Serikat, dan Asia.'
  },
  {
    src: 'https://nextmail-api.dcnlink.com/media/etihad-airways-logo.svg',
    description: 'Etihad Airways 3 adalah maskapai penerbangan nasional dari Uni Emirat Arab yang didirikan pada tahun 2003. Maskapai ini melayani lebih dari 120 destinasi di seluruh dunia, termasuk kota-kota besar di Timur Tengah, Eropa, Amerika Serikat, dan Asia.'
  },
  {
    src: 'https://nextmail-api.dcnlink.com/media/qatar_airways_logo.svg',
    description: 'Etihad Airways 1 adalah maskapai penerbangan nasional dari Uni Emirat Arab yang didirikan pada tahun 2003. Maskapai ini melayani lebih dari 120 destinasi di seluruh dunia, termasuk kota-kota besar di Timur Tengah, Eropa, Amerika Serikat, dan Asia.Etihad Airways adalah maskapai penerbangan nasional dari Uni Emirat Arab yang didirikan pada tahun 2003. Maskapai ini melayani lebih dari 120 destinasi di seluruh dunia, termasuk kota-kota besar di Timur Tengah, Eropa, Amerika Serikat, dan Asia.Etihad Airways adalah maskapai penerbangan nasional dari Uni Emirat Arab yang didirikan pada tahun 2003. Maskapai ini melayani lebih dari 120 destinasi di seluruh dunia, termasuk kota-kota besar di Timur Tengah, Eropa, Amerika Serikat, dan Asia.'
  },
  {
    src: 'https://nextmail-api.dcnlink.com/media/ethiopian_airlines_logo.svg',
    description: 'Etihad Airways 2 adalah maskapai penerbangan nasional dari Uni Emirat Arab yang didirikan pada tahun 2003. Maskapai ini melayani lebih dari 120 destinasi di seluruh dunia, termasuk kota-kota besar di Timur Tengah, Eropa, Amerika Serikat, dan Asia.'
  },
  {
    src: 'https://nextmail-api.dcnlink.com/media/etihad-airways-logo.svg',
    description: 'Etihad Airways 3 adalah maskapai penerbangan nasional dari Uni Emirat Arab yang didirikan pada tahun 2003. Maskapai ini melayani lebih dari 120 destinasi di seluruh dunia, termasuk kota-kota besar di Timur Tengah, Eropa, Amerika Serikat, dan Asia.'
  }
];

mock.onGet('/api/get-maskapai').reply(() => {
  return [200, { data: DATABASE_MASKAPAI }];
});

const DATABASE_TESTIMONIAL = [
  {
    id: 1,
    src: 'https://nextmail-api.dcnlink.com/media/rectangle_8.png',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo.',
    name: 'Kurnia Sari',
    from: 'PT Kombas'
  },
  {
    id: 2,
    src: 'https://nextmail-api.dcnlink.com/media/rectangle_8_(1).png',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    name: 'Kurnia Sari',
    from: 'PT Kombas'
  },
  {
    id: 3,
    src: 'https://nextmail-api.dcnlink.com/media/rectangle_8_(1).png',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    name: 'Kurnia Sari',
    from: 'PT Kombas'
  }

];

mock.onGet('/api/get-testimonial').reply(() => {
  return [200, { data: DATABASE_TESTIMONIAL }];
});

const DATABASE_AIRPORT = [
  {
    option: 'Jakarta, CGK',
    key: '1'
  },
  {
    option: 'Dubai, DXB',
    key: '2'
  },
  {
    option: 'Jeddah, JED',
    key: '3'
  }
];

mock.onGet('/api/airport').reply(() => {
  return [200, { data: DATABASE_AIRPORT }];
});

const DATABASE_BANNER = [
  {
    src: 'https://nextmail-api.dcnlink.com/media/banner-pict-1.webp',
    text1: 'Selamat Datang Di Dunia Spiritual yang Penuh Berkah!',
    text2: 'Nikmati momen istimewa dalam hidupmu dengan melaksanakan ibadah Umrah bersama kami. '
  },
  {
    src: 'https://nextmail-api.dcnlink.com/media/banner-pict-2.jpeg',
    text1: 'Selamat Datang Di Dunia Spiritual yang Penuh Berkah!',
    text2: 'Nikmati momen istimewa dalam hidupmu dengan melaksanakan ibadah Umrah bersama kami. '
  },
  {
    src: 'https://nextmail-api.dcnlink.com/media/banner-pict-3.jpeg',
    text1: 'Selamat Datang Di Dunia Spiritual yang Penuh Berkah!',
    text2: 'Nikmati momen istimewa dalam hidupmu dengan melaksanakan ibadah Umrah bersama kami. '
  }
];

mock.onGet('/api/get-banner').reply(() => {
  return [200, { data: DATABASE_BANNER }];
});

const DATABASE_KHAZANAH = [
  {
    khazanahId: 1,
    title: 'Tips & Trick Umroh ',
    img: 'https://nextmail-api.dcnlink.com/media/khazanah1.jpeg',
    text: "Beberapa doa yang biasa dilakukan ketika melakukan umrah antara lain doa ketika memasuki masjidil haram, doa ketika melihat atau memandang Ka'bah, doa ketika memulai thawaf, doa ketika mencium Hajar Aswad, doa di Maqam Ibrahim, doa di Multazam, doa ketika selesai thawaf, doa ketika memulai sa'i, dan doa ketika selesai melakukan umrah. Doa-doaini penting untuk membantu meningkatkan konsentrasi dan kesadaran spiritual saat menjalankan ibadah umrah, serta menguatkan ikatan batin dengan Sang Pencipta."
  },
  {
    khazanahId: 2,
    title: 'Tempat Wisata Spiritual',
    img: 'https://nextmail-api.dcnlink.com/media/khazanah2.jpeg',
    text: "Beberapa doa yang biasa dilakukan ketika melakukan umrah antara lain doa ketika memasuki masjidil haram, doa ketika melihat atau memandang Ka'bah, doa ketika memulai thawaf, doa ketika mencium Hajar Aswad, doa di Maqam Ibrahim, doa di Multazam, doa ketika selesai thawaf, doa ketika memulai sa'i, dan doa ketika selesai melakukan umrah. Doa-doaini penting untuk membantu meningkatkan konsentrasi dan kesadaran spiritual saat menjalankan ibadah umrah, serta menguatkan ikatan batin dengan Sang Pencipta."
  },
  {
    khazanahId: 3,
    title: 'Tips & Trick Umroh ',
    img: 'https://nextmail-api.dcnlink.com/media/khazanah1.jpeg',
    text: "Beberapa doa yang biasa dilakukan ketika melakukan umrah antara lain doa ketika memasuki masjidil haram, doa ketika melihat atau memandang Ka'bah, doa ketika memulai thawaf, doa ketika mencium Hajar Aswad, doa di Maqam Ibrahim, doa di Multazam, doa ketika selesai thawaf, doa ketika memulai sa'i, dan doa ketika selesai melakukan umrah. Doa-doaini penting untuk membantu meningkatkan konsentrasi dan kesadaran spiritual saat menjalankan ibadah umrah, serta menguatkan ikatan batin dengan Sang Pencipta."
  },
  {
    khazanahId: 4,
    title: 'Tempat Wisata Spiritual',
    img: 'https://nextmail-api.dcnlink.com/media/khazanah2.jpeg',
    text: "Beberapa doa yang biasa dilakukan ketika melakukan umrah antara lain doa ketika memasuki masjidil haram, doa ketika melihat atau memandang Ka'bah, doa ketika memulai thawaf, doa ketika mencium Hajar Aswad, doa di Maqam Ibrahim, doa di Multazam, doa ketika selesai thawaf, doa ketika memulai sa'i, dan doa ketika selesai melakukan umrah. Doa-doaini penting untuk membantu meningkatkan konsentrasi dan kesadaran spiritual saat menjalankan ibadah umrah, serta menguatkan ikatan batin dengan Sang Pencipta."
  },
  {
    khazanahId: 5,
    title: 'Tempat Wisata Spiritual',
    img: 'https://nextmail-api.dcnlink.com/media/khazanah2.jpeg',
    text: "Beberapa doa yang biasa dilakukan ketika melakukan umrah antara lain doa ketika memasuki masjidil haram, doa ketika melihat atau memandang Ka'bah, doa ketika memulai thawaf, doa ketika mencium Hajar Aswad, doa di Maqam Ibrahim, doa di Multazam, doa ketika selesai thawaf, doa ketika memulai sa'i, dan doa ketika selesai melakukan umrah. Doa-doaini penting untuk membantu meningkatkan konsentrasi dan kesadaran spiritual saat menjalankan ibadah umrah, serta menguatkan ikatan batin dengan Sang Pencipta."
  },
  {
    khazanahId: 6,
    title: 'Tempat Wisata Spiritual',
    img: 'https://nextmail-api.dcnlink.com/media/khazanah2.jpeg',
    text: "Beberapa doa yang biasa dilakukan ketika melakukan umrah antara lain doa ketika memasuki masjidil haram, doa ketika melihat atau memandang Ka'bah, doa ketika memulai thawaf, doa ketika mencium Hajar Aswad, doa di Maqam Ibrahim, doa di Multazam, doa ketika selesai thawaf, doa ketika memulai sa'i, dan doa ketika selesai melakukan umrah. Doa-doaini penting untuk membantu meningkatkan konsentrasi dan kesadaran spiritual saat menjalankan ibadah umrah, serta menguatkan ikatan batin dengan Sang Pencipta."
  },
  {
    khazanahId: 7,
    title: 'Tempat Wisata Spiritual',
    img: 'https://nextmail-api.dcnlink.com/media/khazanah2.jpeg',
    text: "Beberapa doa yang biasa dilakukan ketika melakukan umrah antara lain doa ketika memasuki masjidil haram, doa ketika melihat atau memandang Ka'bah, doa ketika memulai thawaf, doa ketika mencium Hajar Aswad, doa di Maqam Ibrahim, doa di Multazam, doa ketika selesai thawaf, doa ketika memulai sa'i, dan doa ketika selesai melakukan umrah. Doa-doaini penting untuk membantu meningkatkan konsentrasi dan kesadaran spiritual saat menjalankan ibadah umrah, serta menguatkan ikatan batin dengan Sang Pencipta."
  }
];

mock.onGet('/api/get-khazanah').reply(() => {
  return [200, { data: DATABASE_KHAZANAH }];
});

const DATABASE_PROMOSI = [
  {
    id: 1,
    src: 'https://nextmail-api.dcnlink.com/media/card_(3).png',
    title: 'Promo Buy One Get 1',
    text: 'Kami dengan bangga mengumumkan program Buy One Get One yang akan memberikan kesempatan untuk mendapatkan tiket gratis untuk setiap pembelian tiket pesawat.'
  },
  {
    id: 2,
    src: 'https://nextmail-api.dcnlink.com/media/card_(3).png',
    title: 'Promo Buy Two Get 2',
    text: 'Kami dengan bangga mengumumkan program Buy Two Get Two yang akan memberikan kesempatan untuk mendapatkan tiket gratis untuk setiap pembelian tiket pesawat.'
  },
  {
    id: 3,
    src: 'https://nextmail-api.dcnlink.com/media/card_(3).png',
    title: 'Promo Buy Three Get 3',
    text: 'Kami dengan bangga mengumumkan program Buy Three Get Three yang akan memberikan kesempatan untuk mendapatkan tiket gratis untuk setiap pembelian tiket pesawat.'
  },
  {
    id: 4,
    src: 'https://nextmail-api.dcnlink.com/media/card_(3).png',
    title: 'Promo Buy Four Get 4',
    text: 'Kami dengan bangga mengumumkan program Buy One Get One yang akan memberikan kesempatan untuk mendapatkan tiket gratis untuk setiap pembelian tiket pesawat.'
  },
  {
    id: 5,
    src: 'https://nextmail-api.dcnlink.com/media/card_(3).png',
    title: 'Promo Buy Five Get 5',
    text: 'Kami dengan bangga mengumumkan program Buy Two Get Two yang akan memberikan kesempatan untuk mendapatkan tiket gratis untuk setiap pembelian tiket pesawat.'
  },
  {
    id: 6,
    src: 'https://nextmail-api.dcnlink.com/media/card_(3).png',
    title: 'Promo Buy Six Get 6',
    text: 'Kami dengan bangga mengumumkan program Buy Three Get Three yang akan memberikan kesempatan untuk mendapatkan tiket gratis untuk setiap pembelian tiket pesawat.'
  }

];

mock.onGet('/api/get-promo').reply(() => {
  return [200, { data: DATABASE_PROMOSI }];
});

const DATABASE_DAFTAR = [
  {
    src: 'https://nextmail-api.dcnlink.com/media/daftar-image.webp',
    caption1: '“Dengan aplikasi ini, saya dapat dengan mudah menemukan paket perjalanan yang sesuai dengan kebutuhan saya”',
    caption2: 'John Doe 1'
  },
  {
    src: 'https://nextmail-api.dcnlink.com/media/daftar-image.webp',
    caption1: '“Dengan aplikasi ini, saya dapat dengan mudah menemukan paket perjalanan yang sesuai dengan kebutuhan saya”',
    caption2: 'John Doe 2'
  }
];

mock.onGet('/api/get-daftar').reply(() => {
  return [200, { data: DATABASE_DAFTAR }];
});

const DATABASE_LOGIN = [
  {
    src: 'https://nextmail-api.dcnlink.com/media/login-image.webp',
    caption1: 'Masuk untuk memulai perjalanan Anda!',
    caption2: 'Perjalanan sprititual tanpa batas yang kami hadirkan untuk melayani anda.'
  },
  {
    src: 'https://nextmail-api.dcnlink.com/media/login-image.webp',
    caption1: 'Masuk untuk memulai perjalanan Anda!',
    caption2: 'Perjalanan sprititual tanpa batas yang kami hadirkan untuk melayani anda.'
  }
];

mock.onGet('/api/get-login').reply(() => {
  return [200, { data: DATABASE_LOGIN }];
});

const DATABASE_RINCIAN_PEMBAYARAN = [
  {
    uraian: 'Booking Fee',
    batas_pembayaran: '2023-01-01 06:00.00',
    jumlah: 400000
  },
  {
    uraian: 'Down Payment 1',
    batas_pembayaran: '2023-02-01 12:00:00',
    jumlah: 150000
  },
  {
    uraian: 'Down Payment 2',
    batas_pembayaran: '2023-03-01 15:00:00',
    jumlah: 250000
  },
  {
    uraian: 'Full Payment',
    batas_pembayaran: '2023-04-01 20:00:00',
    jumlah: 250000
  }
];

mock.onGet('/api/rincian-pembayaran').reply(() => {
  return [200, { data: DATABASE_RINCIAN_PEMBAYARAN }];
});

const DATABASE_TABLE_PESANAN = [
  {
    no_pesanan: 'AABB1234',
    pax: 1,
    penumpang: '',
    deparuture_date: '18/01/2023',
    pnr: 'H1231(HK)',
    status_pesanan: 'Menunggu Pendaftaran Nama',
    harga_pax: 15000000
  },
  {
    no_pesanan: 'AABB1235',
    pax: 2,
    penumpang: '',
    keberangkatan: '18/01/2024',
    pnr: 'H1231(HK)',
    status_pesanan: 'Menunggu Pendaftaran Nama',
    harga_pax: 25000000
  }
];

mock.onPost('/api/post-table-pesanan').reply(() => {
  return [200, { data: DATABASE_TABLE_PESANAN }];
});

const DATABASE_DETAIL_PENERBANGAN = [
  {
    keterangan: 'CGK - JED - CGK',
    nominal: 10000000,
    pax: 36,
    total: 360000000,
    uraian: 'Tiket SV121, SV122 22 Agustus 2022'
  },
  {
    keterangan: '',
    nominal: 0,
    pax: 0,
    total: 0,
    uraian: ''
  }
];

mock.onGet('/api/get-detail').reply(() => {
  return [200, { data: DATABASE_DETAIL_PENERBANGAN }];
});

const DATABASE_RINCIAN_HARGA = [
  {
    keterangan: '',
    nominal: '',
    pax: '',
    total: '',
    uraian: ''
  }
];

mock.onGet('/api/get-rincian-harga').reply(() => {
  return [200, { data: DATABASE_RINCIAN_HARGA }];
});

const DATABASE_PELUNASAN = [
  {
    keterangan: '',
    nominal: '',
    pax: '',
    total: '',
    uraian: ''
  }
];

mock.onGet('/api/get-pelunasan').reply(() => {
  return [200, { data: DATABASE_PELUNASAN }];
});

const DATABASE_PERHITUNGAN_PELUNASAN = [
  {
    keterangan: '',
    total: '',
    uraian: ''
  }
];

mock.onGet('/api/get-perhitungan-pelunasan').reply(() => {
  return [200, { data: DATABASE_PERHITUNGAN_PELUNASAN }];
});

mock.onPost('/api/post-table-pesanan').reply(() => {
  return [200, { data: DATABASE_TABLE_PESANAN }];
});

mock.onAny().passThrough();

export { API };
