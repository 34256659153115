interface CarouselDataModel {
  caption1: string
  caption2: string
  src: string
}

interface RegisterDataModel {
  created_at: string
  created_by: string
  description: string
  id: string
  metadata: string
  name: string
  platform_admin: boolean
  tenant_id: string
  updated_by: string
}

export interface CredentialsModel {
  carouselLogin: CarouselDataModel[]
  carouselRegister: CarouselDataModel[]
  register: RegisterDataModel
}

export enum CredentialsActionType {
  GetCarouselRegister = '⌘➝App➝Credentials➝GetCarouselRegister',
  GetCarouselLogin = '⌘➝App➝Credentials➝GetCarouselLogin',
  PostRegister = '⌘➝App➝Credentials➝PostRegister'
}

export type CredentialsAction = {
  data: CarouselDataModel[]
  type: CredentialsActionType.GetCarouselLogin
} |
{
  data: CarouselDataModel[]
  type: CredentialsActionType.GetCarouselRegister
} |
{
  data: RegisterDataModel
  type: CredentialsActionType.PostRegister
};
