import type { FC } from 'react';

import { NavbarUser } from './navbar-user';

interface NavbarProps {
  colorChange?: boolean
  openNavbar?: boolean
  toggleOpenNavbar?: () => void
}

const Navbar: FC<NavbarProps> = ({
  colorChange,
  openNavbar,
  toggleOpenNavbar
}) => {
  return (
    <NavbarUser colorChange={colorChange} openNavbar={openNavbar} toggleOpenNavbar={toggleOpenNavbar} />
  );
};

Navbar.displayName = 'Navbar';

export { Navbar };
