import { APIDCN, apiFetch } from '@api/base';
import { API } from '@api/mock';
import { bs58, decoder } from '@lib/utils';
import type { TAction, TDispatch } from '@models/types';

import { CredentialsActionType } from './types';

import type { CredentialsAction, CredentialsModel } from './types';

interface PostRegisterProps {
  [key: string]: string
  address: string
  email: string
  name: string
  password: string
  phone: string
}

const CredentialsDefault: CredentialsModel = {
  carouselLogin: [],
  carouselRegister: [],
  register: {
    created_at: '',
    created_by: '',
    description: '',
    id: '',
    metadata: '',
    name: '',
    platform_admin: false,
    tenant_id: '',
    updated_by: ''
  }
};

const CredentialsReducer = (state: CredentialsModel = CredentialsDefault, action: CredentialsAction): CredentialsModel => {
  switch (action.type) {
    case CredentialsActionType.GetCarouselRegister:
      return { ...state, carouselRegister: action.data };
    case CredentialsActionType.GetCarouselLogin:
      return { ...state, carouselLogin: action.data };
    case CredentialsActionType.PostRegister:
      return { ...state, register: action.data };

    default:
      return { ...state };
  }
};

const CredentialsCommand = {
  getDaftarList: (): TAction<CredentialsAction, void> => {
    return (dispatch: TDispatch<CredentialsAction>) => {
      return API.get('/api/get-daftar')
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: CredentialsActionType.GetCarouselRegister
          });
        });
    };
  },
  getLoginList: (): TAction<CredentialsAction, void> => {
    return (dispatch: TDispatch<CredentialsAction>) => {
      return API.get('/api/get-login')
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: CredentialsActionType.GetCarouselLogin
          });
        });
    };
  },
  getUserMetadata: (params: { email: string }, token: string): Promise<string> => {
    return apiFetch(token).get('/registration/1.0/agent/user-metadata', { params })
      .then((response) => {
        return response.data.data;
      });
  },
  postAktivasi: (data: string): Promise<string> => {
    const bs58Decoded = bs58.decode(data);
    const decodedString = decoder.decode(bs58Decoded);

    return APIDCN.post('/registration/1.0/agent/activation', JSON.parse(decodedString))
      .then((response) => {
        if (response.status === 200) {
          return 'ok';
        }

        return 'err';
      }).catch(() => {
        return 'err';
      });
  },
  postCheckEmail: (payload: { email: string }): Promise<boolean> => {
    return APIDCN.post('/registration/1.0/check-email-exists', payload)
      .then((response) => {
        if (response.status === 200) {
          return response.data.isEmailExists;
        }
      });
  },
  postCheckPhone: (payload: { phone: string }): Promise<boolean> => {
    return APIDCN.post('/registration/1.0/check-phone-exists', payload)
      .then((response) => {
        if (response.status === 200) {
          return response.data.isPhoneExists;
        }
      });
  },
  postRegister: (payload: PostRegisterProps): Promise<string> => {
    return APIDCN.post('/registration/1.0/agent/signup', payload)
      .then((response) => {
        if (response.status === 200) {
          return 'ok';
        }

        return 'err';
      }).catch(() => {
        return 'err';
      });
  }
};

export { CredentialsCommand, CredentialsReducer, CredentialsDefault };
export type { PostRegisterProps };
