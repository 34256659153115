import type { FC } from 'react';
import { Col, Collapse, Container, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useAuth } from '@nxweb/auth';
import { ArrowDownCircleFill, Pencil, Power } from '@nxweb/icons/bootstrap';
import { Grid } from '@nxweb/icons/feather';

import { IconAlteira, IconAlteira2 } from '@components/icon';
import { NAVBAR_ITEMS, USER_DROPDOWN_ITEMS } from '@config/navbar';

import { UserDropdown } from './user-dropdown';

interface NavbarUserProps {
  colorChange?: boolean
  openNavbar?: boolean
  toggleOpenNavbar?: () => void
}

const EMPTY_FUNC = () => { };

const NavbarUser: FC<NavbarUserProps> = ({ colorChange = false, openNavbar = false, toggleOpenNavbar = EMPTY_FUNC }) => {
  const auth = useAuth();
  const loggedIn = auth.data?.loggedIn;
  const filteredNavbarItems = loggedIn
    ? NAVBAR_ITEMS
    : NAVBAR_ITEMS.filter((item) => item.title !== 'Booking');

  return (
    <Container className="d-inline">
      <Row>
        <Col className="d-flex justify-content-center align-items-center" md={3} xs={5}>
          <Link to="/">
            <div>
              {openNavbar || colorChange
                ? <IconAlteira height={50} width={150} />
                : <IconAlteira2 height={50} width={179} />}
            </div>
          </Link>
        </Col>

        <Col className="d-none d-lg-flex justify-content-center align-items-center" xs={6}>
          <Nav defaultActiveKey="/" variant="underline">
            <Nav.Item>
              <ul className="nav navbar-nav align-items-center gap-50">
                {filteredNavbarItems.map((o) => {
                  return (
                    <Nav.Item key={o.title}>
                      <Link
                        className="nav-link"
                        to={typeof o.to === 'string' ? o.to : '#'}
                        onClick={() => (typeof o.to !== 'string' ? o.to() : {})}
                      >
                        <span className="navbar-item-spacing">{o.title}</span>
                      </Link>
                    </Nav.Item>
                  );
                })}
              </ul>
            </Nav.Item>
          </Nav>
        </Col>

        <Col className="d-flex justify-content-end align-items-center">
          <Nav.Item className="d-none d-lg-block">
            <UserDropdown />
          </Nav.Item>
          <Nav.Item className="d-block d-lg-none">
            <Grid
              aria-controls="collapsable-navbar"
              aria-expanded={openNavbar}
              className={!(openNavbar || colorChange) ? 'text-white' : ''}
              size={24}
              onClick={toggleOpenNavbar} />
          </Nav.Item>
        </Col>
      </Row>
      <Collapse in={openNavbar}>
        <div>
          <ul className="nav navbar-nav collapsable-navbar align-items-center gap-50 pb-0" id="collapsable-navbar">
            {filteredNavbarItems.map((o) => {
              return (
                <Nav.Item key={o.title}>
                  <Link
                    className="nav-link"
                    to={typeof o.to === 'string' ? o.to : '#'}
                    onClick={() => (typeof o.to !== 'string' ? o.to() : {})}
                  >
                    <span className="navbar-item-spacing">{o.title}</span>
                  </Link>
                </Nav.Item>
              );
            })}
          </ul>
          {loggedIn
            ? <>
              <hr />
              <ul className="nav navbar-nav collapsable-navbar align-items-center gap-1 pt-0" id="collapsable-navbar">
                {USER_DROPDOWN_ITEMS.map((o) => {
                  return (
                    <Nav.Item key={o.title}>
                      <Link
                        className="nav-link"
                        to={o.to}
                      >
                        {o.icon}
                        <span className="navbar-item-spacing">{o.title}</span>
                      </Link>
                    </Nav.Item>
                  );
                })}
                <Nav.Item>
                  <Link
                    className="nav-link"
                    to="/#"
                    onClick={() => auth.logout()}
                  >
                    <Power className="text-danger" size={14} />
                    <span className="navbar-item-spacing text-danger">Logout</span>
                  </Link>
                </Nav.Item>
              </ul>
              </>
            : <>
              <hr />
              <ul className="nav navbar-nav collapsable-navbar align-items-center gap-1 pt-0" id="collapsable-navbar">
                <Nav.Item>
                  <Link
                    className="nav-link"
                    to="/daftar"
                  >
                    <Pencil className="text-primary" size={14} />
                    <span className="navbar-item-spacing text-primary">Sign Up</span>
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    className="nav-link"
                    to="/#"
                    onClick={() => auth.loginWithRedirect()}
                  >
                    <ArrowDownCircleFill className="text-primary" size={14} />
                    <span className="navbar-item-spacing text-primary">Login</span>
                  </Link>
                </Nav.Item>
              </ul>
              </>}
        </div>
      </Collapse>
    </Container>
  );
};

NavbarUser.displayName = 'NavbarUser';

export { NavbarUser };
export type { NavbarUserProps };
